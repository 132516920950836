import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { t } from '../../../../../../src/common/i18n'
import Layout from '../../../../../../src/common/components/Layout'
import SpinnerWithText from '../../../../../../src/common/components/spinnerWithText/SpinnerWithText'
import { addFromExternal } from '../../../../../../src/common/context/actions/cestaActions'
import { Helmet } from 'react-helmet'
import { Dispatch } from 'redux'

interface Props {
  addToCestaFromExternal: (productId: string, codTaller: string) => void
}

const ProxyAddProduct = ({ addToCestaFromExternal }: Props) => {
  const isFirstRender = React.useRef(true)
  useEffect(() => {
    isFirstRender.current = false
  }, [])

  if (typeof window !== 'undefined' && isFirstRender.current) {
    const query = new URLSearchParams(window.location.search)
    const productId = query.get('codProducto')
    const codigoTaller = query.get('codTaller')
    addToCestaFromExternal(productId, codigoTaller)
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta name={'robots'} content={'noindex,nofollow'} />
      </Helmet>
      <Layout seoData={null} currentLocation="cesta" selector={true}>
        <SpinnerWithText text={t('cesta.general.cargando')} />
      </Layout>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addToCestaFromExternal: (productId: string, codigoTaller: string) =>
      dispatch(addFromExternal(productId, codigoTaller)),
  }
}

export default connect(null, mapDispatchToProps)(ProxyAddProduct)
